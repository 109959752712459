/*jshint freeze:true, latedef:true, nocomma:true, nonbsp:true, nonew:true, strict:true, undef:true, unused:true, node:false, browser:true, jquery:true, devel: true*/
/*globals LMLIndex*/
(function(){
"use strict";

Math.log2 = Math.log2 || function(x) {
  return Math.log(x) * Math.LOG2E;
};

function AudioContextMock(){
	this.time = Date.now();
}

AudioContextMock.prototype.createOscillator = function(){
	return {
		frequency:{},
		connect:function(){},
		start:function(){}
	};
};

AudioContextMock.prototype.createGain = function(){
	return {
		gain:{
			setValueAtTime:function(){},
		},
		connect:function(){},
	};
};

Object.defineProperty(AudioContextMock.prototype,'currentTime',{get:function(){
	return (Date.now()-this.time)/1000;
}});

window.AudioContextMock = AudioContextMock;
function Output(el,buffer,offset){
	this.el = el;
	this.buffer = buffer;
	this.endline = offset;
	this.offset = offset;
	this.init = offset;
	this.lineoffsets = [offset-20];
	this.redraw();
}

Output.prototype.renderline = function(line,offset){
	var h = line.height();
	line.html('');
	var words = [];
	var cnt = 0;
	while(cnt===0 || (cnt < 1024 && h == line.height())){
		cnt++;
		var word = '';
		while(this.buffer.getChar(offset) != ' ')
			word += this.buffer.getChar(offset++);
		offset += 1;
		line.append(word.split('').map(function(x){
			return '<span>'+x+'</span>';
		}).join('')+' ');
		words.push(word);
	}
	if(words.length > 1){
		words.pop();
		while(words.length > 1 && words[words.length-1].length == 1 && words[words.length-1] != '-')
			words.pop();
	}
	return words.join(' ');
};

Output.prototype.redraw = function(offset){
	this.el.html('');
	var now = offset || this.offset;
	var init = this.init;
	this.offset = this.lineoffsets[0];
	this.endline = this.lineoffsets[0];
	this.lineoffsets = [];
	this.newline(true);
	while(this.endline < now){
		this.offset = this.endline;
		this.newline(true);
	}
	this.el.find('div span').each(function(){
		var id = this.id|0;
		if(id < now)
			$(this).css('visibility','visible');
		if(id < init)
			$(this).addClass('gray');
	});
	this.offset = now;
};

Output.prototype.newline = function(wrap){
	var line = $('<div>&nbsp;</div>');
	this.el.append(line);
	this.lineoffsets.push(this.offset);
	if(this.lineoffsets.length > 1 && this.el.parent()[0].scrollHeight > this.el.parent()[0].clientHeight){
		this.el.find('div:first').remove();
		this.lineoffsets.shift();
	}
	var text = this.renderline(line,this.endline);
	this.linestr = text;
	var offset = this.offset;
	if(wrap){
		line.html(text.split('').map(function(x,k){
			return x==' '?' ':'<span id="'+(offset+k)+'">'+x+'</span>';
		}).join(''));
	}else{
		line.html(text);
	}
	this.endline += text.length+1;
};

Output.prototype.next = function(){
	var c = this.buffer.getChar(this.offset);
	if(c == ' '){
		this.offset++;
		return;
	}
	if(this.offset >= this.endline)
		this.newline(true);
	$('#'+(this.offset++)).css('visibility','visible');
};

function Sync(clock){
	this.clock = clock;
	this.offset = 0;
	this.oncomplete = null;
	this.onerror = null;
	this._locked = false;
}

Sync.prototype.enable = function(delay){
	var self = this;
	var f = function(){
		if(self._locked)
			return;
		self._locked = true;
		var cnt = 0;
		var offsetTable = [];
		function loop(){
			var ta,tb;
			var xhr = new XMLHttpRequest();
			xhr.open('HEAD', '/time.php?'+Math.random(), true);
			xhr.addEventListener("readystatechange",function(){
				if(xhr.readyState == 2)
				   tb = self.clock.now();
			});
			xhr.addEventListener("loadend", function(){
				if(this.status != 200){
					self._locked = false;
					return self.onerror?self.onerror():null;
				}
				var trip = tb-ta;
				offsetTable.push(this.getResponseHeader('MTIME') - ta - trip/2);
				cnt += 1;
				if(cnt == 20){
					self.offset = offsetTable.sort().slice(5,15).reduce(function(a,b){return a+b;})/10;
					self._locked = false;
					return self.oncomplete?self.oncomplete(self.offset):null;
				}
				loop();
			});
			xhr.send("");
			ta = self.clock.now();
		}
		loop();
	};
	this.interval = setInterval(f,delay);
	f();
};

Sync.prototype.disable = function(){
	clearInterval(this.interval);
};

function TextBuffer(length, chunksize){
	this.length = length;
	this.chunk = chunksize;
	this.lchunk = Math.log2(chunksize);
	this.data = [];
	for(var i=0; i < Math.ceil(length/chunksize); i++)
		this.data.push(null);
}

//todo: error handler
TextBuffer.prototype.getChar = function(pos){
	pos %= this.length;
	var data = this.data;
	var k = pos >>> this.lchunk;
	if(typeof data[k] === 'string')
		return data[k][pos&(this.chunk-1)];
	if(data[k] === null){
		$.get('chunks/lml_'+k+'.txt',function(text){
			data[k] = text;
		});
		data[k] = 1;
	}
	throw "stop";
};

function Generator(freq,length,dot){
	this.freq = freq;
	this.dot = dot;
	this.length = length;
	this.pos = 0;
	this.time = 0;
	this.offset = 0;
	this._error = true;
	this.lastchar = null;
	var AudioContext = window.AudioContext || window.webkitAudioContext || AudioContextMock;
	this.context = new AudioContext();
	this.osc = this.context.createOscillator();
	this.osc.type = 'sine';
	this.osc.frequency.value = freq;
	this.gain = this.context.createGain();
	this.gain.gain.value = 0;
	this.osc.connect(this.gain);
	this.gain.connect(this.context.destination);
	this.osc.start(Math.ceil((this.context.currentTime+dot)*freq)/freq);
}

Generator.prototype.start = function(){
	var self = this;
	this.output = null;
	this.clock = setInterval(function(){
		//$('body').append(self.context.currentTime+' ');
		if(self.context.currentTime <= self.time)
			return;
		try{
			if(self._error)
				return self._restart();
			if(self.lastchar)
				self.output.next();
			//$('div').append(self.lastchar);
			//$('div').append((self.time+self.offset)+"<br/>");
			var c = self.textBuffer.getChar(self.pos++);
			self.lastchar = c;
			if(c == ' '){
				//console.log(Math.abs(sync.offset-self.offset));
				if(Math.abs(self.sync.offset-self.offset) > 3*self.dot)
					throw "stop";
				self.time += 4*self.dot + self.offset - self.sync.offset;
				self.offset = self.sync.offset;
			}else{
				self.time += self.dot*2;
				self._mkletter(c);
			}
		}catch(e){
			if(e !== 'stop')
				throw e;
			self._error = true;
		}
	},50);
};

Generator.prototype.now = function(){
	return this.context.currentTime;
};

Generator.prototype._restart = function(){
	this.offset = this.sync.offset;
	var base = Math.ceil((this.context.currentTime+this.offset+(this.context.baseLatency||0)+0.2)/this.dot);
	var state = this._time2pos(base%this.length);
	this.time = (base - (base%this.length) + state[0])*this.dot - this.offset - (this.context.baseLatency||0);
	this.pos = state[1];
	this._error = false;
	if(!this.output){
		this.output = new Output($('#main'),this.textBuffer,this.pos);
	}else if(this.pos != this.output.offset){
		this.output.redraw(this.pos);
	}
};

Generator.prototype._mkletter = function(c){
	var code = c.charCodeAt(0);
	var x = Generator._LOOKUP[code];
	var len = x >>> 8;
	var shape = x & 0xFF;
	while(len > 0){
		if(shape&1){
			this._schedule(1,this.time);
			this._schedule(0,this.time+this.dot*3);
			this.time += this.dot*4;
			len -= 4;
		}else{
			this._schedule(1,this.time);
			this._schedule(0,this.time+this.dot);
			this.time += this.dot*2;
			len -= 2;
		}
		shape >>>= 1;
	}
};

Generator.prototype._schedule = function(value,time){
	this.gain.gain.setValueAtTime(value,Math.ceil(time*this.freq)/this.freq);
};

Generator.prototype._time2pos = function(dottime){
	var v = LMLIndex[dottime >>> 14];
	var len = ((dottime>>>14)<<14)-(v>>>24);
	var i = v&0xFFFFFF;
	while(len < dottime){
		var c = this.textBuffer.getChar(i++).charCodeAt(0);
		len += (Generator._LOOKUP[c]>>>8)+2;
		len %= this.length;
	}
	return [len,i];
};

Generator._LOOKUP = [
	0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,
	0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,
	0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,
	0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,
	0x0200,0x0000,0x1012,0x0000,0x0000,0x0000,0x0000,0x141e,
	0x100d,0x142d,0x0000,0x0000,0x1433,0x1021,0x122a,0x0e09,
	0x141f,0x121e,0x101c,0x0e18,0x0c10,0x0a00,0x0c01,0x0e03,
	0x1007,0x120f,0x1207,0x1215,0x0000,0x0000,0x0000,0x100c,
	0x0000,0x0602,0x0a01,0x0c05,0x0801,0x0200,0x0a04,0x0a03,
	0x0800,0x0400,0x0e0e,0x0a05,0x0a02,0x0803,0x0601,0x0c07,
	0x0c06,0x0e0b,0x0802,0x0600,0x0401,0x0804,0x0a08,0x0a06,
	0x0c09,0x0e0d,0x0c03,0x0000,0x0000,0x0000,0x0000,0x0000,
	0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,
	0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,
	0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,
	0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,0x0000,0x0000
];

this.Generator = Generator;
this.Sync = Sync;
this.TextBuffer = TextBuffer;

}).call(this);
