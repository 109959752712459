$(function(){
"use strict";

var requireTouch = (window.AudioContext || window.webkitAudioContext) && !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
var synced = false;

var TIME = 0.1;
var FREQ = 440;

var LENGTH = 2394382;
var CHUNKSIZE = 32*1024;
var DOTLENGTH = 20240270;

var generator = new Generator(FREQ,DOTLENGTH,TIME);

generator.textBuffer = new TextBuffer(LENGTH,CHUNKSIZE);
generator.sync = new Sync(generator);
generator.sync.oncomplete = function(){
	generator.sync.oncomplete = null;
	synced = true;
	if(!requireTouch)
		generator.start();
};
generator.sync.enable(10000);
generator.start();

$(window).resize(function() {
	$('#main').css('min-height',$('#box').height()-$('#footer').outerHeight()-$('#banner').outerHeight());
	if(generator.output)
		generator.output.redraw();
});
$(window).resize();

if(requireTouch){
	$('#overlay').show();
	var ios_unlock_sound = function(){
		var buffer = generator.context.createBuffer(1, 1, 22050);
		var source = generator.context.createBufferSource();
		source.buffer = buffer;
		source.connect(generator.context.destination);
		source.start(0);
		window.removeEventListener("touchend", ios_unlock_sound, false);
		window.removeEventListener("mouseup", ios_unlock_sound, false);
		requireTouch = false;
		$('#overlay').hide();
		if(synced)
			generator.start();
	};
	window.addEventListener("touchend", ios_unlock_sound, false);
	window.addEventListener("mouseup", ios_unlock_sound, false);
}

});